import { useState } from 'react'
import { When } from 'react-if'

type TProps = {
  children?: string
  length?: number
}

const TextOverflow = ({ children = '', length = 180 }: TProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const text = children || ''
  const textFormatted =
    text.length > length && !expanded ? text.substring(0, length) + '...' : text

  return (
    <div>
      <span
        dangerouslySetInnerHTML={{
          __html: textFormatted,
        }}
      />
      <When condition={text.length > length}>
        {' '}
        <button
          className="text-teal-800 hover:underline"
          onClick={(e) => {
            e.stopPropagation()
            setExpanded((oldState) => !oldState)
          }}
        >
          Read {expanded ? 'less' : 'more'}
        </button>
      </When>
    </div>
  )
}

export default TextOverflow
