import classNames from 'classnames'
import { Divider, Text } from '@changex/design-system'
import { When } from 'react-if'
import { TFilter, TSolution } from '../../types'

type TProps = {
  data?: TSolution['aggregates']
  expanded: boolean
  onFiltering: (filters: TFilter) => void
}

const ApplicationsSummaryChallenge = ({
  data,
  expanded,
  onFiltering,
}: TProps) => (
  <div
    className={classNames({
      'border-r border-gray-200 px-6 py-5': expanded,
    })}
  >
    <div>
      <When condition={expanded}>
        <Text className="mb-2.5" size="xs" upper variant="light">
          Project activation
        </Text>
      </When>
    </div>
    <div className={classNames('flex', { 'gap-x-8': expanded })}>
      <When condition={expanded}>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-preallocated-funding"
          onClick={() => {
            onFiltering({
              status: 'pre_allocated',
            })
          }}
        >
          <Text className="text-teal-400" size="2xl" weight="medium">
            {data?.preAllocated || 0}
          </Text>
          <Text size="xs" variant="light">
            Preallocated
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-allocated-funding"
          onClick={() => {
            onFiltering({
              status: 'allocated',
            })
          }}
        >
          <Text className="text-teal-400" size="2xl" weight="medium">
            {data?.allocated || 0}
          </Text>
          <Text size="xs" variant="light">
            Allocated
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-succeeded"
          onClick={() => {
            onFiltering({
              status: 'succeeded',
            })
          }}
        >
          <Text className="text-teal-400" size="2xl" weight="medium">
            {data?.succeeded || 0}
          </Text>
          <Text className="whitespace-nowrap" size="xs" variant="light">
            Succeeded
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-approved"
          onClick={() => {
            onFiltering({
              status: 'approved',
            })
          }}
        >
          <Text className="text-teal-400" size="2xl" weight="medium">
            {data?.approved || 0}
          </Text>
          <Text className="whitespace-nowrap" size="xs" variant="light">
            Approved
          </Text>
        </div>
        <When condition={!expanded}>
          <Divider orientation="vertical" />
        </When>
      </When>
      <div
        className="flex cursor-pointer flex-col hover:underline"
        data-testid="text-paid-seed"
        onClick={() => {
          onFiltering({
            status: 'paid_seed',
          })
        }}
      >
        <Text className="text-pink-600" size="2xl" weight="medium">
          {data?.paidSeed || 0}
        </Text>
        <Text className="whitespace-nowrap" size="xs" variant="light">
          Paid seed
        </Text>
      </div>
      <When condition={!expanded}>
        <Divider orientation="vertical" />
      </When>
    </div>
  </div>
)

export default ApplicationsSummaryChallenge
