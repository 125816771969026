import { ApiClient, ApiProvider } from 'jsonapi-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import schema from './schema'

type TProps = {
  children: React.ReactNode | React.ReactNode[]
  queryClient?: QueryClient
}

export const client = new ApiClient({
  url: process.env.REACT_APP_API_ADDRESS,
  fetchOptions: {
    credentials: 'include',
  },
  schema,
  cacheTime: 5 * 60,
  staleTime: 60,
})

const defaultQueryClient = new QueryClient()

const QueryInfrastructure = ({
  children,
  queryClient = defaultQueryClient,
}: TProps) => (
  <QueryClientProvider client={queryClient}>
    <ApiProvider client={client}>{children}</ApiProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

export default QueryInfrastructure
