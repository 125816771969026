import { useQuery as useJsonApiQuery } from 'jsonapi-react'
import { http } from '@http'
import { utils } from './utils'
import {
  TFund,
  TGalleryFilter,
  TSolution,
  TSolutionPhoto,
  TUser,
} from './types'
import { get, merge, orderBy } from 'lodash'
import { constants } from './constants'
import { useSearchParams } from 'react-router-dom'
import { useMemo, useState } from 'react'

const useNotes = () => {
  const putChangexNotes = (note: string, applicationId: number) => {
    console.log('Putting changex notes...', note, applicationId)
  }

  const putTeamNotes = async (note: string, applicationId: number) => {
    try {
      const response = await http.put(`/applications/${applicationId}`, {
        data: {
          id: applicationId,
          type: 'solution_applications',
          attributes: { teamNotes: note },
        },
      })
      console.log('response', response)
    } catch (err) {
      console.log(err)
    }
  }

  return { putChangexNotes, putTeamNotes }
}

const useSolutionById = (solutionId: string | number) => {
  const { data, isLoading, error } = useJsonApiQuery<TSolution>([
    `solutions/${solutionId}`,
    {
      include: 'funds',
      filter: {
        funds: { state: 'published,closed' },
      },
    },
  ])
  return { data, isLoading, error }
}

const useAuth = () => {
  const { data } = useJsonApiQuery<TUser>(['/me'])
  return { data }
}

const useLikePhotoMutation = () => {
  return {
    mutate: async (input: { id: string | number }) => {
      return await http.post('/likes', {
        data: {
          type: 'likes',
          relationships: {
            likeable: {
              data: { id: input.id, type: 'assets' },
            },
          },
        },
      })
    },
  }
}

const useUnlikePhotoMutation = () => {
  return {
    mutate: async (input: { id: string | number }) => {
      return await http.delete(`/likes/${input.id}?type=assets`)
    },
  }
}

const useFeaturePhotoMutation = () => {
  return {
    mutate: async (input: { id: string | number }) => {
      return await http.post('/featureds', {
        data: {
          attributes: {
            featureable_id: Number(input.id),
            type: 'assets',
          },
        },
      })
    },
  }
}

const useUnfeaturePhotoMutation = () => {
  return {
    mutate: async (input: { id: string | number }) => {
      return await http.delete(`/featureds/${input.id}?type=assets`)
    },
  }
}

const useGalleryFilters = (solutionId: string | undefined) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const filtersObj = utils.parseQueryString(Object.fromEntries(searchParams))
  const [filters, setFilters] = useState({ ...filtersObj })

  const allFilters = useMemo(() => {
    return merge({}, utils.getDefaultGalleryFilter(solutionId), filters)
  }, [filters, solutionId])

  const updateSearchParams = (newFilters: TGalleryFilter): void => {
    const flatFilters = utils.flattenObject(newFilters)
    Object.keys(flatFilters).forEach((key) => {
      const value = get(newFilters, key)
      if (utils.hasValue(value)) {
        searchParams.set(key, String(value))
      } else {
        searchParams.delete(key)
      }
    })
  }

  const handleFilter = (newFilters: TGalleryFilter): void => {
    const updatedFilter = !newFilters?.page
      ? merge({}, newFilters, { page: constants.defaultGalleryFilters.page })
      : newFilters
    updateSearchParams(updatedFilter)
    const mergedFilters = merge({}, filters, updatedFilter)
    const final = utils.removeEmptyFields(mergedFilters)

    setSearchParams(searchParams)
    setFilters({ ...final })
  }

  const handleResetFilters = () => {
    setFilters({})
    setSearchParams({})
  }

  return { filters, handleFilter, handleResetFilters, allFilters }
}

const useGalleryPagination = (filters, meta, handleFilter) => {
  const totalPhotosCount = meta?.stats.total.count
  const currentPage = filters.page?.number || '1'

  const handlePagination = (pagination) => {
    window.scrollTo({ top: -10 })
    handleFilter({ page: { number: pagination.page.toString() } })
  }

  return { totalPhotosCount, currentPage, handlePagination }
}

const useGalleryImages = (photosData: TSolutionPhoto[] | undefined) => {
  return photosData?.map((photoData) => ({
    src: utils.addSizeToFirestackUrl(photoData.photoUrl, 800, 800),
    alt: '',
  }))
}

const useGalleryFormattedFunds = (solution: TSolution | undefined) => {
  const getFundsFrom = (funds: TFund[] | undefined) =>
    funds?.map((item) => ({
      ...item,
      label: item.name,
      id: Number(item.id),
      active: Boolean(item.isLive),
    })) || []

  return orderBy(
    getFundsFrom(solution?.funds ?? []),
    ['active', 'id'],
    ['desc', 'desc']
  )
}

const useGalleryPage = (solutionId: string) => {
  const [gridViewActive, setGridViewActive] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const handleImageClick = (index: number) => {
    setActiveImageIndex(index)
    setIsOpen(true)
  }
  const { filters, handleFilter, handleResetFilters, allFilters } =
    useGalleryFilters(solutionId)

  const {
    data: photosData,
    meta,
    isFetching,
  } = useJsonApiQuery<TSolutionPhoto[]>(['solution_photos', allFilters])
  const { data: solution } = useSolutionById(solutionId)
  const { totalPhotosCount, currentPage, handlePagination } =
    useGalleryPagination(filters, meta, handleFilter)
  const images = useGalleryImages(photosData)

  const formattedFunds = useGalleryFormattedFunds(solution)

  const galleryFilters = {
    filters,
    handleFilter,
    handleResetFilters,
    formattedFunds,
  }

  const pagination = {
    currentPage,
    meta,
    handlePagination,
  }
  const galleryImages = {
    activeImageIndex,
    images,
    isOpen,
    setIsOpen,
  }
  return {
    galleryFilters,
    setGridViewActive,
    isFetching,
    totalPhotosCount,
    photosData,
    handleImageClick,
    gridViewActive,
    pagination,
    galleryImages,
  }
}

export {
  useNotes,
  useSolutionById,
  useAuth,
  useLikePhotoMutation,
  useUnlikePhotoMutation,
  useFeaturePhotoMutation,
  useUnfeaturePhotoMutation,
  useGalleryFilters,
  useGalleryPagination,
  useGalleryImages,
  useGalleryFormattedFunds,
  useGalleryPage,
}
