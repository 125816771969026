import classNames from 'classnames'
import { Divider, Text } from '@changex/design-system'
import { When } from 'react-if'
import { TFilter, TSolution } from '../../types'

type TProps = {
  data?: TSolution['aggregates']
  expanded: boolean
  onFiltering: (filters: TFilter) => void
}

const ApplicationsSummaryOverview = ({
  data,
  expanded,
  onFiltering,
}: TProps) => {
  const dataWithoutInChallenge = Object.entries(data || {})
    .filter(([key, _value]) => key !== 'inChallenge')
    .map(([_key, value]) => value)

  return (
    <div
      className={classNames({
        'border-r border-gray-200 px-6 py-5': expanded,
      })}
    >
      <div>
        <When condition={expanded}>
          <Text className="mb-2.5" size="xs" upper variant="light">
            Overview
          </Text>
        </When>
      </div>
      <div className={classNames('flex', { 'gap-x-8': expanded })}>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-total-applications"
          onClick={() => {
            onFiltering({
              status: '',
              in_challenge: '',
              funded: '',
            })
          }}
        >
          <Text size="2xl" weight="medium">
            {dataWithoutInChallenge.reduce(
              (total, aggregate) => (total += aggregate),
              0
            )}
          </Text>
          <Text className="whitespace-nowrap" size="xs" variant="light">
            Total applicants
          </Text>
        </div>
        {!expanded ? (
          <>
            <When condition={!expanded}>
              <Divider orientation="vertical" />
            </When>
            <div
              className="hidden cursor-pointer flex-col hover:underline"
              data-testid="text-in-challenge"
              onClick={() => {
                onFiltering({
                  in_challenge: true,
                })
              }}
            >
              <Text size="2xl" weight="medium">
                {data?.inChallenge || 0}
              </Text>
              <Text className="whitespace-nowrap" size="xs" variant="light">
                In challenge
              </Text>
            </div>
            <When condition={!expanded}>
              <div className="hidden">
                <Divider orientation="vertical" />
              </div>
            </When>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default ApplicationsSummaryOverview
