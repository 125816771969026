import { LineGraph } from '@changex/design-system'
import { TSolutionApplicationsOverTime } from 'shared/api/analytics'
import dayjs from 'dayjs'

type TProps = {
  data: TSolutionApplicationsOverTime
}

const formatLabels = (data: TSolutionApplicationsOverTime) => {
  return Object.keys(data).map((label) => {
    return dayjs(label, 'YYYYMMDD').format('D MMM YY')
  })
}

const formatValues = (data: TSolutionApplicationsOverTime) => {
  return Object.values(data).reduce((acc, value) => {
    if (acc.length === 0) {
      return [value.length]
    }
    acc.push(acc[acc.length - 1] + value.length)
    return acc
  }, [] as number[])
}

const ApplicationsOverTime = ({ data }: TProps) => {
  return (
    <div className="flex flex-col rounded bg-white p-4">
      <h3 className="text-lg text-gray-800">Applications over time</h3>
      <div className="mx-4 mb-8 mt-12">
        <LineGraph labels={formatLabels(data)} data={formatValues(data)} />
      </div>
    </div>
  )
}

export default ApplicationsOverTime
