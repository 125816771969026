import { useMutation } from 'jsonapi-react'
import { Button, Text } from '@changex/design-system'
import { TApplication } from '../../types'
import dayjs from 'dayjs'
import { Else, If, Then } from 'react-if'
import { useState } from 'react'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useAuth } from '@features/applications/hooks'

type TProps = {
  data?: TApplication | null
}

const ApplicationDetailsCall = ({ data: dataFromProps }: TProps) => {
  const { data: userData } = useAuth()
  const [data, setData] = useState(dataFromProps)
  const callStartTime = data?.callStartTime
  const receivedStarterCallAt = data?.receivedStarterCallAt
  const dataFormat = 'MMM D, YYYY, HH:mm a'

  const callScheduleDateFormatted = dayjs(callStartTime).format(dataFormat)
  const receivedCallScheduleDateFormatted = dayjs(receivedStarterCallAt).format(
    dataFormat
  )

  const [updateApplicant, { isLoading, data: updatedData }] =
    useMutation<TApplication>(['applications', data?.id])

  useEffect(() => {
    if (updatedData) {
      setData({ ...data, ...updatedData })
      toast.success('The applicant has been updated.')
    }
  }, [updatedData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mt-9">
      <div>
        <Text variant="light">Call scheduled</Text>
        <Text className="ml-4">
          <If condition={!!callStartTime}>
            <Then>{callScheduleDateFormatted.toString()}</Then>
            <Else>No</Else>
          </If>
        </Text>
      </div>
      <If condition={!!receivedStarterCallAt}>
        <Then>
          <div>
            <Text variant="light">ChangeX Call at</Text>
            <Text className="ml-4">
              {receivedCallScheduleDateFormatted.toString()}
            </Text>
          </div>
        </Then>
      </If>
      <If
        condition={!!receivedStarterCallAt && userData?.userType !== 'cx_admin'}
      >
        <Then>
          <div className="mt-1.5">
            <Button
              className="ml-[125px] !py-1.5 !px-2.5"
              weight="tertiary"
              onClick={async () => {
                await updateApplicant({
                  receivedStarterCallAt: new Date().toISOString(),
                })
              }}
              loading={isLoading}
            >
              Mark as call received (by ChangeX)
            </Button>
          </div>
        </Then>
      </If>
    </div>
  )
}

export default ApplicationDetailsCall
