import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { routes as applicationsRoutes } from '@features/applications/routes'
import { routes as impactRoutes } from '@features/impact/routes'
import { routes as notFoundRoutes } from '@features/not-found/routes'
import { routes as paymentsRoutes } from '@features/payments/routes'

import { TRoute } from '@types'

const routes: TRoute[] = [
  ...applicationsRoutes,
  ...impactRoutes,
  ...paymentsRoutes,
  ...notFoundRoutes,
]

const RoutingInfrastructure = () => (
  <BrowserRouter>
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  </BrowserRouter>
)

export default RoutingInfrastructure
