import { useState } from 'react'
import classNames from 'classnames'
import { If, Else, Then } from 'react-if'
import { Button, Navbar } from '@changex/design-system'
import { TFilter, TSolution } from '../../types'
import ApplicationsSummaryChallenge from './applications-summary-challenge'
import ApplicationsSummaryImpact from './applications-summary-impact'
import ApplicationsSummaryOthers from './applications-summary-others'
import ApplicationsSummaryOverview from './applications-summary-overview'

type TProps = {
  data?: TSolution['aggregates']
  onFiltering: (filters: TFilter) => void
}

const ApplicationsSummary = ({ data, onFiltering }: TProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const handleMoreDetailsClick = () => setExpanded((oldState) => !oldState)

  const handleFilter = (filters: TFilter) => {
    const obj = {
      q: '',
      funded: false,
      in_challenge: false,
      status: '',
      fund: '',
      step: '',
    }
    onFiltering({ ...obj, ...filters })
  }

  return (
    <Navbar
      className={classNames('mt-8 flex items-stretch overflow-x-auto', {
        '!p-0': expanded,
      })}
    >
      <ApplicationsSummaryOverview
        data={data}
        expanded={expanded}
        onFiltering={handleFilter}
      />
      <ApplicationsSummaryChallenge
        data={data}
        expanded={expanded}
        onFiltering={handleFilter}
      />
      <ApplicationsSummaryImpact
        data={data}
        expanded={expanded}
        onFiltering={handleFilter}
      />
      <ApplicationsSummaryOthers
        data={data}
        expanded={expanded}
        onFiltering={handleFilter}
      />
      <div className={classNames('flex items-center', { 'px-6': expanded })}>
        <Button
          className="h-fit whitespace-nowrap"
          onClick={handleMoreDetailsClick}
          weight="tertiary"
        >
          <If condition={expanded}>
            <Then>Less details</Then>
            <Else>More details</Else>
          </If>
        </Button>
      </div>
    </Navbar>
  )
}

export default ApplicationsSummary
