import { Icon, Text } from '@changex/design-system'
import classNames from 'classnames'
import { Else, If, Then } from 'react-if'

export type TProps = {
  completed?: boolean
  name: string
  position: number
}

const Step = ({ completed, name, position }: TProps) => (
  <div className="flex flex-col items-center">
    <div
      className={classNames(
        'flex h-11 w-11 items-center justify-center rounded-full',
        {
          'bg-green-500': completed,
          'bg-gray-200': !completed,
        }
      )}
    >
      <If condition={completed}>
        <Then>
          <small className="text-lg text-white">{position}</small>
        </Then>
        <Else>
          <Icon
            icon="LockClosed"
            size="sm"
            variant="solid"
            className="text-gray-400"
          />
        </Else>
      </If>
    </div>
    <Text className="mt-1.5 capitalize" size="sm" variant="light">
      {name}
    </Text>
  </div>
)

export default Step
