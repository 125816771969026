import { useState } from 'react'
import { If, Else, Then, When } from 'react-if'
import { Text, SlideOver } from '@changex/design-system'
import styled from 'styled-components'
import { TApplication } from '../../types'
import Application from './application'
import ApplicationDetails from '../application-details'
import Skeleton from './skeleton'

type TProps = {
  meta: { [key: string]: any } | undefined
  data: TApplication[]
  isLoading: boolean
  onChangexNotes: (note: string, applicationId: number) => void
  onTeamNotes: (note: string, applicationId: number) => void
}

const List = styled.div`
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`

const ApplicationsList = ({
  meta,
  data,
  isLoading,
  onChangexNotes,
  onTeamNotes,
}: TProps) => {
  const [selectedApplication, setSelectedApplication] =
    useState<TApplication | null>(null)
  const [slideOpen, setSlideOpen] = useState<boolean>(false)

  const handleApplicationClick = (application) => {
    setSelectedApplication(application)
    setSlideOpen(true)
  }

  const handleCloseSlide = () => {
    setSelectedApplication(null)
    setSlideOpen(false)
  }

  const total = meta?.stats?.total?.count

  return (
    <div className="mt-5">
      <If condition={isLoading}>
        <Then>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Then>
        <Else>
          <Text variant="light">{total || 0} applications found</Text>
          <When condition={data?.length > 0}>
            <List className="mt-5 rounded-md border border-gray-200">
              {data?.map((application) => (
                <Application
                  data={application}
                  key={application.id}
                  onClick={handleApplicationClick}
                />
              ))}
            </List>
            <SlideOver
              children={
                <ApplicationDetails.Body
                  data={selectedApplication}
                  onChangexNotes={onChangexNotes}
                  onTeamNotes={onTeamNotes}
                />
              }
              open={slideOpen}
              title={<ApplicationDetails.Title data={selectedApplication} />}
              onClose={handleCloseSlide}
              size="lg"
            />
          </When>
        </Else>
      </If>
    </div>
  )
}

export default ApplicationsList
