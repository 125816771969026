import { useSolutionById } from '@features/applications/hooks'
import { utils } from '@features/applications/utils'
import PhotoActions from '../photo-actions'
import { TFund, TPhotoCard } from '@features/applications/types'
import {
  PhotoCardImage,
  PhotoCardWrapper,
  PhotoCardDetail,
} from '@changex/design-system'

const PhotoCard = ({
  id,
  photoUrl,
  postUrl,
  locationName,
  locationAddress,
  createdAt,
  isFavourite,
  isFeatured,
  isPublic,
  solutionId,
  handleImageOnClick,
  isGridView,
  fundId,
}: TPhotoCard) => {
  const solutionsQuery: any = useSolutionById(solutionId)
  const funds: TFund[] = solutionsQuery.data?.funds || []
  const fundDetail = funds.find((f) => parseInt(f.id) === fundId)
  const linkDetails = [
    {
      label: 'Project',
      href: postUrl,
      text: locationName,
    },
  ]

  if (fundDetail?.name) {
    linkDetails.push({
      label: 'Fund',
      href: utils.createLinkToFund({
        fund: { slug: fundDetail?.slug },
      }),
      text: fundDetail.name,
    })
  }

  return (
    <PhotoCardWrapper isGridView={isGridView}>
      <PhotoCardImage
        photoUrl={photoUrl}
        handleImageOnClick={handleImageOnClick}
        altText={locationName}
      >
        <PhotoActions
          id={id}
          isFavourite={isFavourite}
          isFeatured={isFeatured}
          isPublic={isPublic}
        />
      </PhotoCardImage>
      <PhotoCardDetail
        links={linkDetails}
        lightTexts={[locationAddress, createdAt]}
      />
    </PhotoCardWrapper>
  )
}

export default PhotoCard
