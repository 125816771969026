import RoutingInfrastructure from './routing.infrastructure'
import QueryInfrastructure from './query.infrastructure'
import LaunchDarklyInfrastructure from './launchdarkly.infrastructure'

const RootInfrastructure = () => (
  <QueryInfrastructure>
    <LaunchDarklyInfrastructure>
      <RoutingInfrastructure />
    </LaunchDarklyInfrastructure>
  </QueryInfrastructure>
)

export default RootInfrastructure
