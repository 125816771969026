import {
  ApplicationEntityType,
  Avatar,
  Icon,
  Text,
} from '@changex/design-system'
import { When } from 'react-if'
import { TApplication } from '../../../types'
import { utils } from '../../../utils'

type TProps = {
  data: TApplication
}

const ApplicationOwner = ({ data }: TProps) => (
  <div className="flex flex-1">
    <div className="mr-4">
      <Avatar sourceUrl={data.owner?.avatarUrl || ''} />
    </div>
    <div className="flex flex-col">
      <div className="mb-1 flex items-center gap-1.5">
        <Text size="lg">{data.owner?.fullName}</Text>
        {data?.entityType && (
          <ApplicationEntityType entityType={data.entityType} />
        )}
      </div>
      <Text variant="light" size="sm">
        {data.owner?.email}
      </Text>
      <When condition={data.owner?.phone}>
        <Text className="mt-6 flex" variant="light" size="sm">
          <Icon className="mr-1.5" icon="Phone" size="sm" />
          {data.owner?.phone}
        </Text>
      </When>
      <When condition={data.location?.address}>
        <Text className="mt-2" size="sm">
          <a
            href={utils.createLinkToLocation(data)}
            rel="noreferrer"
            target="_blank"
            className="hover:underline"
          >
            {data.location?.address}
          </a>
        </Text>
      </When>
    </div>
  </div>
)

export default ApplicationOwner
