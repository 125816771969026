import {
  Checkbox,
  Selector,
  Button,
  TreeSelector,
} from '@changex/design-system'
import styled from 'styled-components'
import { TGalleryFilter, TFundsSwitcherItem } from '../../types'
import { constants } from '../../constants'
import { XIcon } from '@heroicons/react/solid'
import { useSearchParams } from 'react-router-dom'
import { get, isEmpty, set } from 'lodash'
import { utils } from '@features/applications/utils'

const isFilterApplied = (filtersObj) => {
  const newObj = { ...filtersObj }
  if (newObj?.page) {
    delete newObj.page
  }
  return Object.keys(newObj).length > 0
}

type TProps = {
  onFilter: (filters: TGalleryFilter) => void
  onReset: () => void
  filters: TGalleryFilter
  funds: TFundsSwitcherItem[]
}

const StyledSelector = styled(Selector)`
  div {
    margin-top: 0;
  }

  ul {
    margin-top: 0.5rem;
  }
`

const GalleryFilters = ({ onFilter, filters, onReset, funds }: TProps) => {
  const [searchParams] = useSearchParams()
  const filtersObj = utils.parseQueryString(Object.fromEntries(searchParams))

  const handleChange = (name: string, value: string) => {
    const filterObject = set({}, name, value)
    onFilter(filterObject)
  }

  const handleReset = () => {
    onReset()
  }

  return (
    <div className="flex items-center gap-x-4">
      <div className="z-30 flex items-center">
        <StyledSelector
          onChange={(option) => {
            handleChange('filter.fund_id', option)
          }}
          options={[...constants.funds, ...funds]}
          value={
            Number(get(filters, 'filter.fund_id'))
              ? Number(get(filters, 'filter.fund_id'))
              : ''
          }
          filterOptions={{
            enabled: true,
            fluid: {
              width: true,
            },
            placeholder: 'Filter funds by name',
          }}
        />
      </div>
      <div className="z-30 flex items-center">
        <TreeSelector
          title={'Status'}
          options={constants.stateCategories}
          value={
            !isEmpty(get(filters, 'filter.state'))
              ? String(get(filters, 'filter.state'))?.split(',')
              : []
          }
          onChange={(value) => {
            handleChange('filter.state', value.join(','))
          }}
        />
      </div>

      <div className="flex items-center">
        <Checkbox
          label="Show featured"
          checked={!!get(filters, 'filter.is_featured')}
          onChange={() =>
            handleChange(
              'filter.is_featured',
              get(filters, 'filter.is_featured') ? '' : 'true'
            )
          }
          testId="checkbox-in-featured"
        />
      </div>
      <div className="flex items-center">
        <Checkbox
          label="Show favourites"
          checked={!!get(filters, 'filter.is_favourite')}
          onChange={() =>
            handleChange(
              'filter.is_favourite',
              get(filters, 'filter.is_favourite') ? '' : 'true'
            )
          }
          testId="checkbox-in-favourites"
        />
      </div>

      <div>
        {isFilterApplied(filtersObj) ? (
          <Button
            weight="tertiary"
            className="border-0 text-red-600 shadow-none"
            onClick={handleReset}
          >
            <XIcon className="h-5 w-5 text-red-600" />
            Clear filters
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default GalleryFilters
