import { Text } from '@changex/design-system'
import React from 'react'
import { TApplication } from '../../types'
import TextOverflow from '../text-overflow'

type TProps = {
  data?: TApplication | null
}

const ApplicationDetailsMotivation = ({ data }: TProps) => (
  <div className="flex flex-col">
    <Text size="xs" variant="light" upper>
      Motivation
    </Text>
    <Text className="mt-3.5">
      <TextOverflow>{data?.message}</TextOverflow>
    </Text>
  </div>
)

export default ApplicationDetailsMotivation
