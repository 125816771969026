import { Button, Heading, Pagination } from '@changex/design-system'
import { TApplication, TFilter, TSolution } from '../types'
import ApplicationsSummary from '../components/applications-summary'
import ApplicationsList from '../components/applications-list'
import ApplicationsFilters from '../components/applications-filters'
import StatusLegend from '../components/status-legend'
import { useParams, useSearchParams } from 'react-router-dom'
import { orderBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { Api } from '@api'

type TProps = {
  meta: { [key: string]: any } | undefined
  applications: TApplication[]
  loadingApplications: boolean
  loadingSolution: boolean
  solution?: TSolution
  onSearchApplications: (solutionId: string | number, filter: TFilter[]) => void
  onChangexNotes: (note: string, applicationId: number) => void
  onTeamNotes: (note: string, applicationId: number) => void
}

const getFundsFrom = (funds) =>
  funds?.map((item) => ({
    ...item,
    label: item.name,
    id: Number(item.id),
    active: new Date(item.options.endDate) > new Date(),
  }))

const ListPage = ({
  meta,
  applications,
  loadingApplications,
  solution,
  onSearchApplications,
  onChangexNotes,
  onTeamNotes,
}: TProps) => {
  const { solutionId } = useParams()
  const [searchParams] = useSearchParams()
  const currentPage = searchParams.get('page') || '1'

  const { isFetching, refetch: fetchExport } = useQuery({
    queryKey: ['export', solutionId],
    queryFn: () => Api.exportSolutionApplicants(solutionId),
    enabled: false,
    onSuccess: () => {
      toast.success(
        'An export of applicants will be emailed to you in the next few minutes.',
        { duration: 5000 }
      )
    },
  })

  const formattedFunds = orderBy(
    getFundsFrom(solution?.funds ?? []),
    ['active', 'id'],
    ['desc', 'desc']
  )

  const handleFilter = (filters) => {
    onSearchApplications(solution?.id || '', filters)
  }

  return (
    <div>
      <div className="flex justify-between">
        <Heading>Applications</Heading>
        <div className="flex">
          <StatusLegend />
          <Button onClick={fetchExport} weight="tertiary" loading={isFetching}>
            Download
          </Button>
        </div>
      </div>
      <ApplicationsSummary
        data={solution?.aggregates}
        onFiltering={handleFilter}
      />
      <ApplicationsFilters onFiltering={handleFilter} funds={formattedFunds} />
      <ApplicationsList
        meta={meta}
        data={applications}
        isLoading={loadingApplications}
        onChangexNotes={onChangexNotes}
        onTeamNotes={onTeamNotes}
      />
      <div className="mt-4">
        <Pagination
          meta={meta}
          onFiltering={handleFilter}
          currentPage={Number(currentPage)}
        />
      </div>
    </div>
  )
}

export default ListPage
