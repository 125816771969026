import { Icon, Tooltip } from '@changex/design-system'
import {
  useLikePhotoMutation,
  useUnlikePhotoMutation,
  useFeaturePhotoMutation,
  useUnfeaturePhotoMutation,
} from '@features/applications/hooks'
import { useState } from 'react'
import toast from 'react-hot-toast'
import classnames from 'classnames'

type PhotoActionsProps = {
  id: string | number
  isFavourite: boolean
  isFeatured: boolean
  isPublic: boolean
}

const PhotoActions = ({
  id,
  isFavourite,
  isFeatured,
  isPublic,
}: PhotoActionsProps) => {
  const [favourite, setFavourite] = useState(isFavourite)
  const [feature, setFeature] = useState(isFeatured)
  const likePhoto = useLikePhotoMutation()
  const unlikePhoto = useUnlikePhotoMutation()
  const featurePhoto = useFeaturePhotoMutation()
  const unfeaturePhoto = useUnfeaturePhotoMutation()

  const handleFavouriteClick = async (
    favourite: boolean,
    id: string | number
  ) => {
    if (favourite) {
      try {
        await unlikePhoto.mutate({ id })
        setFavourite(false)
      } catch (error) {
        toast.error((error as Error).message)
      }
    } else {
      try {
        await likePhoto.mutate({ id })
        setFavourite(true)
      } catch (error) {
        toast.error((error as Error).message)
      }
    }
  }
  const handleFeatureClick = async (feature: boolean, id: string | number) => {
    if (feature) {
      try {
        await unfeaturePhoto.mutate({ id })
        setFeature(false)
      } catch (error) {
        toast.error((error as Error).message)
      }
    } else {
      try {
        await featurePhoto.mutate({ id })
        setFeature(true)
      } catch (error) {
        toast.error((error as Error).message)
      }
    }
  }

  return (
    <div className="absolute top-3 right-3 z-20 flex gap-2">
      <Tooltip>
        <Tooltip.Trigger asChild={true}>
          <button
            aria-label={feature ? 'Remove from featured' : 'Add to featured'}
            onClick={() => handleFeatureClick(feature, id)}
            className={classnames(
              'z-20 h-10 w-10 rounded-full opacity-0 transition-opacity duration-300 group-hover:opacity-100',
              {
                'bg-white': isPublic,
                'bg-gray-100': !isPublic,
              }
            )}
            disabled={!isPublic}
          >
            <Icon
              className={classnames('flex justify-center', {
                'text-yellow-400': isPublic,
                'text-gray-400': !isPublic,
              })}
              icon="Star"
              size="sm"
              variant={`${feature ? 'solid' : 'outline'}`}
            />
          </button>
        </Tooltip.Trigger>
        {!isPublic && (
          <Tooltip.Content>
            <div className="w-32 text-center">
              This photo is from a private page so it can't be featured
            </div>
          </Tooltip.Content>
        )}
        {isPublic && !feature ? (
          <Tooltip.Content>
            <div className="w-32 text-center">Add to featured images</div>
          </Tooltip.Content>
        ) : null}
      </Tooltip>
      <Tooltip>
        <Tooltip.Trigger asChild={true}>
          <button
            aria-label={
              favourite ? 'Remove from favourites' : 'Add to favourites'
            }
            onClick={() => handleFavouriteClick(favourite, id)}
            className="z-20 h-10 w-10 rounded-full bg-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
          >
            <Icon
              className="flex justify-center text-red-500"
              icon="Heart"
              size="sm"
              variant={`${favourite ? 'solid' : 'outline'}`}
            />
          </button>
        </Tooltip.Trigger>
        {!favourite ? (
          <Tooltip.Content>
            <div className="w-32 text-center">Add to favourites</div>
          </Tooltip.Content>
        ) : null}
      </Tooltip>
    </div>
  )
}

export default PhotoActions
