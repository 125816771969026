import { Menu, Header } from '@components'
import { useSolutionById, useAuth } from '@features/applications/hooks'
import { useParams, useLocation } from 'react-router-dom'
import { LoadingSpinner } from '@changex/design-system'
import { useState } from 'react'
import { useEffect } from 'react'

type TProps = {
  children: React.ReactNode | React.ReactNode[]
}

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

const AppLayout = ({ children }: TProps) => {
  const [feedback, setFeedback] = useState('Hang tight')
  const { solutionId } = useParams()
  const { error, isLoading } = useSolutionById(solutionId ?? 0)
  const { data: user } = useAuth()
  const location = useLocation()

  useEffect(() => {
    if (error) {
      if (error.status === '500' || error.status === '401') {
        setFeedback('You are being redirected to login page...')
        setTimeout(() => {
          const currentUrl = window.location.href
          window.location.href = `${CHANGEX_ADDRESS}/users/sign_in?return_to=${currentUrl}`
        }, 1500)
      }
    }
  }, [error, isLoading])

  useEffect(() => {
    if (!!window.Intercom as boolean) {
      window.Intercom('update', { app_id: 'ind4d18u' })
    }
  }, [location])

  return (
    <>
      <Header user={user} />
      {isLoading || error ? (
        <div className="center scale-200 text-primery flex py-10 px-8">
          <LoadingSpinner /> {feedback}
        </div>
      ) : (
        <div className="flex">
          <Menu />
          <div className="flex-grow">{children}</div>
        </div>
      )}
    </>
  )
}

export default AppLayout
