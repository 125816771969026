import { Text } from '@changex/design-system'
import React from 'react'
import { TApplicationAdditionalData } from '../../types'
import { Divider } from '@changex/design-system'

type TProps = {
  data?: TApplicationAdditionalData | null
}

function formatAnswer(answer: Object) {
  if (answer['type'] === 'FILE_UPLOAD') {
    return (
      <a href={answer['answer']} target="_blank" rel="noreferrer">
        Open File
      </a>
    )
  } else {
    return <Text>{answer['answer']}</Text>
  }
}

const ApplicationDetailsAdditionalData = ({ data }: TProps) => {
  if (!data) {
    return null
  }
  return (
    <>
      {Object.keys(data).map((category) => (
        <div key={category}>
          <Divider />
          <div className="mt-3.5">
            <Text size="xs" variant="light" upper>
              {category}
            </Text>
            {data[category].map((category_item) => {
              return (
                <div className="mt-9" key={category_item.question}>
                  <Text variant="light">{category_item.question}</Text>
                  <div className="mt-1.5">{formatAnswer(category_item)}</div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </>
  )
}

export default ApplicationDetailsAdditionalData
