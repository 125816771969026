import { Badge, Text } from '@changex/design-system'
import { If, Then } from 'react-if'
import { TApplication } from 'features/applications/types'
import { utils } from 'features/applications/utils'

type TProps = {
  data: TApplication
}

const ApplicationInfo = ({ data }: TProps) => {
  const status = data.applicationStatus || 'n/a'
  const fundName = data.fund?.name
  return (
    <div className="flex flex-1 flex-col gap-3" data-testid="application-info">
      <div data-testid="status" className="flex">
        <div className="w-[90px] flex-shrink-0 leading-5">
          <Text className="mr-3" variant="light" size="xs" upper>
            Status
          </Text>
        </div>
        <Badge variant={utils.getBadgeStatus(status)}>
          {utils.getStatusLabel(status)}
        </Badge>
      </div>
      <If condition={fundName}>
        <Then>
          <div data-testid="fund" className="flex">
            <div className="w-[90px] flex-shrink-0 leading-5">
              <Text className="mr-3" variant="light" size="xs" upper>
                Fund
              </Text>
            </div>
            <Text size="sm">
              <a
                href={utils.createLinkToFund(data)}
                rel="noreferrer"
                target="_blank"
                className="hover:underline"
              >
                {fundName}
              </a>
            </Text>
          </div>
        </Then>
      </If>
      <div data-testid="page" className="flex">
        <div className="w-[90px] flex-shrink-0 leading-5">
          <Text className="mr-3" variant="light" size="xs" upper>
            Project
          </Text>
        </div>
        <Text size="sm">
          <a
            href={utils.createLinkToPage(data)}
            rel="noreferrer"
            target="_blank"
            className="hover:underline"
          >
            {data.location?.name}
          </a>
        </Text>
      </div>
    </div>
  )
}

export default ApplicationInfo
