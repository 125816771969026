import classNames from 'classnames'
import { Divider, Text } from '@changex/design-system'
import { When } from 'react-if'
import { TFilter, TSolution } from '../../types'

type TProps = {
  data?: TSolution['aggregates']
  expanded: boolean
  onFiltering: (filters: TFilter) => void
}

const ApplicationsSummaryImpact = ({ data, expanded, onFiltering }: TProps) => (
  <div
    className={classNames({
      'border-r border-gray-200 px-6 py-5': expanded,
    })}
  >
    <When condition={expanded}>
      <div data-testid="text-title">
        <Text className="mb-2.5" size="xs" upper variant="light">
          Impact
        </Text>
      </div>
    </When>
    <div className={classNames('flex', { 'gap-x-8': expanded })}>
      <When condition={expanded}>
        <div
          className="flex cursor-pointer flex-col hover:underline"
          data-testid="text-impact"
          onClick={() => {
            onFiltering({
              status: 'impact',
            })
          }}
        >
          <Text className="text-teal-800" size="2xl" weight="medium">
            {data?.impact || 0}
          </Text>
          <Text className="whitespace-nowrap" size="xs" variant="light">
            Impact
          </Text>
        </div>
      </When>
      <div
        className="flex cursor-pointer flex-col hover:underline"
        data-testid="text-paid-impact"
        onClick={() => {
          onFiltering({
            status: 'paid_impact',
          })
        }}
      >
        <Text className="text-teal-800" size="2xl" weight="medium">
          {data?.paidImpact || 0}
        </Text>
        <Text className="whitespace-nowrap" size="xs" variant="light">
          Paid impact
        </Text>
      </div>
      <When condition={!expanded}>
        <Divider orientation="vertical" />
      </When>
    </div>
  </div>
)

export default ApplicationsSummaryImpact
